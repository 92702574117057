import { FC } from 'react'

export const CopyIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M21.7853 0H6.05768C5.6142 0 5.2533 0.360901 5.2533 0.804382V3.57166H2.25751C1.81403 3.57166 1.45312 3.93237 1.45312 4.37585V23.1956C1.45312 23.6391 1.81403 24 2.25751 24H17.9852C18.4286 24 18.7896 23.6391 18.7896 23.1956V20.4283H21.7853C22.2288 20.4283 22.5897 20.0676 22.5897 19.6241V0.804382C22.5897 0.360901 22.2288 0 21.7853 0ZM2.85938 22.5938V4.97791H13.7058V7.87006C13.7058 8.29504 14.0517 8.64093 14.4767 8.64093H17.3831V19.7249V19.7252V19.7258V22.5938H2.85938ZM15.1122 5.96814L16.3834 7.2345H15.1122V5.96814ZM21.1835 19.0221H18.7896V7.98083C18.7896 7.76624 18.7061 7.56427 18.5532 7.41119L14.9357 3.80695C14.7838 3.65515 14.5818 3.57166 14.3672 3.57166H6.65973V1.40625H21.1833V19.0221H21.1835Z"
        fill="currentColor"
      />
    </svg>
  )
}
