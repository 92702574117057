import { FC } from 'react'

interface DiscardIconProps {
  size?: number
}

export const DiscardIcon: FC<DiscardIconProps> = ({ size }) => {
  return (
    <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_250_93)">
        <path
          d="M11.9999 24C9.62679 23.9996 7.30706 23.2957 5.33401 21.9771C3.36095 20.6585 1.82316 18.7845 0.915058 16.592C0.00695619 14.3995 -0.230681 11.987 0.232192 9.65942C0.695065 7.33189 1.83766 5.19385 3.51553 3.51563C5.77682 1.31949 8.81144 0.101308 11.9636 0.124344C15.1157 0.147381 18.1322 1.40979 20.3612 3.63874C22.5901 5.8677 23.8525 8.88419 23.8756 12.0363C23.8986 15.1885 22.6804 18.2231 20.4843 20.4844C19.3727 21.6022 18.0505 22.4885 16.5942 23.092C15.1379 23.6954 13.5763 24.0041 11.9999 24ZM11.9999 1.40625C6.15834 1.40625 1.40615 6.15844 1.40615 12C1.40615 17.8416 6.15834 22.5938 11.9999 22.5938C17.8415 22.5938 22.5937 17.8416 22.5937 12C22.5937 6.15844 17.8415 1.40625 11.9999 1.40625Z"
          fill="currentColor"
        />
        <path
          d="M16.1839 16.8873C16.0916 16.8875 16.0002 16.8694 15.9149 16.834C15.8297 16.7987 15.7522 16.7469 15.6871 16.6815L7.31847 8.31293C7.19039 8.18032 7.11952 8.00271 7.12112 7.81835C7.12272 7.634 7.19667 7.45764 7.32703 7.32728C7.4574 7.19691 7.63375 7.12297 7.81811 7.12136C8.00247 7.11976 8.18008 7.19063 8.31269 7.31871L16.6813 15.6873C16.7795 15.7857 16.8464 15.9109 16.8735 16.0473C16.9005 16.1836 16.8866 16.325 16.8333 16.4534C16.7801 16.5818 16.69 16.6916 16.5744 16.7688C16.4588 16.846 16.323 16.8873 16.1839 16.8873Z"
          fill="currentColor"
        />
        <path
          d="M7.81577 16.8873C7.67675 16.8873 7.54087 16.846 7.42528 16.7688C7.30969 16.6916 7.2196 16.5818 7.16637 16.4534C7.11315 16.325 7.09918 16.1836 7.12625 16.0473C7.15331 15.9109 7.22019 15.7857 7.31842 15.6873L15.687 7.31871C15.8196 7.19063 15.9972 7.11976 16.1816 7.12136C16.366 7.12297 16.5423 7.19691 16.6727 7.32728C16.803 7.45764 16.877 7.634 16.8786 7.81835C16.8802 8.00271 16.8093 8.18032 16.6812 8.31293L8.31264 16.6815C8.24748 16.7469 8.17004 16.7987 8.08476 16.834C7.99949 16.8694 7.90807 16.8875 7.81577 16.8873Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_250_93">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
