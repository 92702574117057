import { FC } from 'react'

export const EditIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M22.8503 3.03366C22.3789 2.68008 21.7981 2.53186 21.2147 2.61652C20.6315 2.70112 20.1166 3.0082 19.764 3.48263L18.1328 5.68875V0.703125C18.1328 0.314812 17.8179 0 17.4296 0H0.975098C0.586785 0 0.271973 0.314812 0.271973 0.703125V18.6094C0.271973 18.7881 0.343129 18.9658 0.466645 19.095L4.94424 23.7825C5.07614 23.92 5.262 24 5.45269 24H17.4296C17.8179 24 18.1328 23.6852 18.1328 23.2969V13.1123L23.2981 6.1028C24.0158 5.13389 23.8149 3.75708 22.8503 3.03366ZM4.74957 21.5429L2.6191 19.3125H4.74961L4.74957 21.5429ZM16.7265 22.5938H6.15582V18.6094C6.15582 18.2211 5.841 17.9062 5.45269 17.9062H1.67822V1.40625H16.7265V7.59052L10.7413 15.6848C10.6896 15.7549 10.6512 15.8339 10.6283 15.918L9.53166 19.94C9.45525 20.2203 9.55908 20.5187 9.79304 20.691C9.91669 20.7821 10.0632 20.8281 10.2101 20.8281C10.3411 20.8281 10.4723 20.7915 10.5878 20.718L14.1346 18.4583C14.2076 18.4118 14.2714 18.3522 14.3228 18.2825L16.7265 15.0206V22.5938ZM11.7186 17.2653L12.5036 17.83L11.3672 18.5541L11.7186 17.2653ZM22.1671 5.26716L13.6031 16.8886L12.2956 15.9481L20.8937 4.32009C21.0205 4.14947 21.2062 4.0387 21.4166 4.00819C21.6271 3.97777 21.8366 4.03116 22.0066 4.15866C22.3546 4.41961 22.427 4.91625 22.1671 5.26716Z"
        fill="currentColor"
      />
      <path
        d="M5.44971 8.034H13.0435C13.4318 8.034 13.7466 7.71918 13.7466 7.33087C13.7466 6.94256 13.4318 6.62775 13.0435 6.62775H5.44971C5.06139 6.62775 4.74658 6.94256 4.74658 7.33087C4.74658 7.71918 5.06139 8.034 5.44971 8.034Z"
        fill="currentColor"
      />
      <path
        d="M5.44971 10.8467H13.0435C13.4318 10.8467 13.7466 10.5319 13.7466 10.1436C13.7466 9.75524 13.4318 9.44043 13.0435 9.44043H5.44971C5.06139 9.44043 4.74658 9.75524 4.74658 10.1436C4.74658 10.5319 5.06139 10.8467 5.44971 10.8467Z"
        fill="currentColor"
      />
      <path
        d="M8.27322 12.2531H5.44971C5.06139 12.2531 4.74658 12.5679 4.74658 12.9562C4.74658 13.3445 5.06139 13.6593 5.44971 13.6593H8.27322C8.66154 13.6593 8.97635 13.3445 8.97635 12.9562C8.97635 12.5679 8.66154 12.2531 8.27322 12.2531Z"
        fill="currentColor"
      />
    </svg>
  )
}
