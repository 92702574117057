import { FC } from 'react'

export const SplitIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M1.41178 19.7647H7.7647C8.15456 19.7647 8.47059 20.0807 8.47059 20.4706C8.47059 20.8605 8.15456 21.1765 7.7647 21.1765H0.705891C0.316031 21.1765 0 20.8605 0 20.4706V3.52941C0 3.13955 0.316031 2.82352 0.705891 2.82352H7.7647C8.15456 2.82352 8.47059 3.13955 8.47059 3.52941C8.47059 3.91927 8.15456 4.2353 7.7647 4.2353H1.41178V19.7647ZM11.2941 0.705891C11.2941 0.316031 11.6101 0 12 0C12.3899 0 12.7059 0.316031 12.7059 0.705891V23.2941C12.7059 23.684 12.3899 24 12 24C11.6101 24 11.2941 23.684 11.2941 23.2941V0.705891ZM16.2353 4.2353C15.8454 4.2353 15.5294 3.91927 15.5294 3.52941C15.5294 3.13955 15.8454 2.82352 16.2353 2.82352H23.2941C23.684 2.82352 24 3.13955 24 3.52941V20.4706C24 20.8605 23.684 21.1765 23.2941 21.1765H16.2353C15.8454 21.1765 15.5294 20.8605 15.5294 20.4706C15.5294 20.0807 15.8454 19.7647 16.2353 19.7647H22.5882V4.2353H16.2353Z"
        fill="currentColor"
      />
    </svg>
  )
}
