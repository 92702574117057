import { TabType } from '@/store/CodeView.store'

export const getTabIcon = (type: TabType) => {
  switch (type) {
    case 'configuration':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clipPath="url(#clip0_108_268)">
            <path
              d="M17.3488 19.961H2.66312C2.33582 19.961 2.07031 19.6955 2.07031 19.368V0.553895C2.07031 0.226441 2.33582 -0.0390625 2.66312 -0.0390625H13.262C13.5895 -0.0390625 13.8548 0.226441 13.8548 0.553895C13.8548 0.881349 13.5895 1.1467 13.262 1.1467H3.25607V18.7752H16.756V4.64066C16.756 4.31321 17.0213 4.0477 17.3488 4.0477C17.6763 4.0477 17.9418 4.31321 17.9418 4.64066V19.368C17.9418 19.6955 17.6763 19.961 17.3488 19.961Z"
              fill="currentColor"
            />
            <path
              d="M17.3487 5.23346H13.2619C12.9344 5.23346 12.6689 4.96796 12.6689 4.64065V0.553889C12.6689 0.31402 12.8134 0.097803 13.035 0.0060976C13.2566 -0.0857604 13.5115 -0.0349486 13.6812 0.134577L17.7678 4.22134C17.9375 4.39102 17.9882 4.64599 17.8965 4.86755C17.8048 5.08911 17.5885 5.23346 17.3487 5.23346ZM13.8547 4.0477H15.9172L13.8547 1.98516V4.0477Z"
              fill="currentColor"
            />
            <path
              d="M7.53102 11.5407C7.37752 11.5407 7.22417 11.4815 7.10805 11.3634L5.40395 9.62921C5.1772 9.3985 5.1772 9.02878 5.40395 8.79806L7.10805 7.0639C7.33754 6.83044 7.71291 6.82708 7.94652 7.05673C8.18013 7.28622 8.18334 7.66159 7.95384 7.89505L6.65807 9.21356L7.95384 10.5321C8.18334 10.7657 8.18013 11.1411 7.94652 11.3705C7.83116 11.4841 7.68102 11.5407 7.53102 11.5407Z"
              fill="currentColor"
            />
            <path
              d="M12.3672 11.5407C12.2172 11.5407 12.0672 11.4841 11.9517 11.3706C11.7182 11.1411 11.7149 10.7657 11.9444 10.5322L13.2402 9.21373L11.9444 7.89522C11.7149 7.66161 11.7182 7.28624 11.9517 7.05675C12.1853 6.82726 12.5607 6.83061 12.7902 7.06407L14.4943 8.79808C14.721 9.0288 14.721 9.39867 14.4943 9.62923L12.7902 11.3634C12.6741 11.4815 12.5207 11.5407 12.3672 11.5407Z"
              fill="currentColor"
            />
            <path
              d="M9.08163 11.5407C8.98108 11.5407 8.87915 11.5151 8.78577 11.4612C8.50226 11.2975 8.40506 10.9349 8.56879 10.6513L10.3028 7.64771C10.4665 7.3642 10.8292 7.26685 11.1127 7.43073C11.3962 7.59446 11.4934 7.95701 11.3297 8.24067L9.5957 11.2442C9.48584 11.4343 9.28656 11.5407 9.08163 11.5407Z"
              fill="currentColor"
            />
            <path
              d="M13.6393 14.3088H6.25833C5.93088 14.3088 5.66553 14.0433 5.66553 13.7158C5.66553 13.3884 5.93088 13.123 6.25833 13.123H13.6393C13.9668 13.123 14.2321 13.3884 14.2321 13.7158C14.2321 14.0433 13.9668 14.3088 13.6393 14.3088Z"
              fill="currentColor"
            />
            <path
              d="M13.6393 16.7989H6.25833C5.93088 16.7989 5.66553 16.5334 5.66553 16.2061C5.66553 15.8786 5.93088 15.6131 6.25833 15.6131H13.6393C13.9668 15.6131 14.2321 15.8786 14.2321 16.2061C14.2321 16.5334 13.9668 16.7989 13.6393 16.7989Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_108_268">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )
    case 'get-started':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clipPath="url(#clip0_63_24)">
            <path
              d="M19.9594 4.22611L19.5415 0.965231C19.5076 0.700689 19.2993 0.492399 19.0348 0.458492L15.7739 0.040623C14.2168 -0.158916 12.6245 0.38466 11.5145 1.49467L9.13586 3.87332L7.91362 3.54491C6.8035 3.24661 5.60896 3.56624 4.79615 4.37904L1.62063 7.55456C1.47265 7.70254 1.4148 7.91821 1.4689 8.12037C1.52301 8.32253 1.68079 8.4805 1.8829 8.5348L4.75345 9.30612L5.20144 9.75411L4.15194 10.8036C3.75868 11.1969 3.75868 11.8368 4.15194 12.2301L7.7699 15.848C7.96655 16.0446 8.22484 16.143 8.4831 16.143C8.74139 16.143 8.99968 16.0446 9.19633 15.848L10.2458 14.7985L10.6938 15.2465L11.4651 18.117C11.5194 18.3191 11.6774 18.4769 11.8796 18.531C12.0794 18.5849 12.2973 18.5279 12.4454 18.3793L15.6209 15.2038C16.4337 14.391 16.7534 13.1964 16.4551 12.0863L16.1267 10.8641L18.5053 8.48542C19.6154 7.37549 20.1589 5.7832 19.9594 4.22611ZM8.48314 14.9039L5.09608 11.5169L6.03011 10.5829L9.41716 13.9699L8.48314 14.9039ZM11.2191 14.1144L5.88561 8.78094L8.13801 6.52854L13.4715 11.862L11.2191 14.1144ZM5.62482 5.20775C6.1423 4.69027 6.90276 4.48682 7.60951 4.67671L8.17937 4.82984L4.88201 8.1272L3.16639 7.66621L5.62482 5.20775ZM15.3233 12.3905C15.5132 13.0973 15.3097 13.8577 14.7923 14.3752L12.3338 16.8337L11.8728 15.118L15.1702 11.8207L15.3233 12.3905ZM14.3001 11.0333L8.96667 5.69988L12.3432 2.32337C13.1984 1.46814 14.425 1.0493 15.6249 1.20305L18.4367 1.56338L18.797 4.3751C18.9508 5.5748 18.5319 6.8016 17.6767 7.65683L14.3001 11.0333Z"
              fill="currentColor"
            />
            <path
              d="M13.2602 3.72163C12.4281 4.55373 12.4281 5.90769 13.2602 6.73984C13.6763 7.15595 14.2227 7.36393 14.7693 7.36393C15.3157 7.36393 15.8624 7.15583 16.2784 6.73984C17.1105 5.90769 17.1105 4.55377 16.2784 3.72163C15.4463 2.88948 14.0923 2.88952 13.2602 3.72163ZM15.4497 5.91113C15.0746 6.28634 14.464 6.2863 14.0889 5.91113C13.7137 5.53596 13.7137 4.92547 14.0889 4.55029C14.2765 4.36271 14.5229 4.26892 14.7693 4.26892C15.0157 4.26892 15.2621 4.36271 15.4497 4.55029C15.8249 4.92547 15.8249 5.53596 15.4497 5.91113Z"
              fill="currentColor"
            />
            <path
              d="M5.17 14.8299C4.9412 14.6011 4.57017 14.6011 4.34133 14.8299L0.171628 18.9996C-0.0572094 19.2285 -0.0572094 19.5995 0.171628 19.8283C0.286047 19.9427 0.436014 19.9999 0.585942 19.9999C0.73587 19.9999 0.885876 19.9427 1.00026 19.8283L5.16996 15.6586C5.39883 15.4298 5.39883 15.0588 5.17 14.8299Z"
              fill="currentColor"
            />
            <path
              d="M1.09367 16.1576C1.24364 16.1576 1.39361 16.1004 1.50799 15.986L3.52955 13.9644C3.75839 13.7356 3.75839 13.3646 3.52955 13.1357C3.30075 12.9069 2.92972 12.9069 2.70089 13.1357L0.679319 15.1573C0.450481 15.3861 0.450481 15.7571 0.679319 15.986C0.793737 16.1004 0.943704 16.1576 1.09367 16.1576Z"
              fill="currentColor"
            />
            <path
              d="M6.03548 16.4705L4.01391 18.492C3.78508 18.7209 3.78508 19.0919 4.01391 19.3207C4.12833 19.4351 4.2783 19.4923 4.42823 19.4923C4.57816 19.4923 4.72816 19.4351 4.84254 19.3207L6.86411 17.2991C7.09295 17.0703 7.09295 16.6993 6.86411 16.4704C6.63535 16.2416 6.26432 16.2416 6.03548 16.4705Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_63_24">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )
    case 'user':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 8.33341C11.3808 8.33341 12.5 7.21412 12.5 5.83341C12.5 4.45271 11.3808 3.33341 10 3.33341C8.61929 3.33341 7.50004 4.45271 7.50004 5.83341C7.50004 7.21412 8.61929 8.33341 10 8.33341ZM10 10.0001C12.3012 10.0001 14.1667 8.1346 14.1667 5.83341C14.1667 3.53223 12.3012 1.66675 10 1.66675C7.69886 1.66675 5.83337 3.53223 5.83337 5.83341C5.83337 8.1346 7.69886 10.0001 10 10.0001Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 13.3334C5.65905 13.3334 4.16667 14.8258 4.16667 16.6667V17.5001C4.16667 17.9603 3.79357 18.3334 3.33333 18.3334C2.8731 18.3334 2.5 17.9603 2.5 17.5001V16.6667C2.5 13.9053 4.73857 11.6667 7.5 11.6667H12.5C15.2614 11.6667 17.5 13.9053 17.5 16.6667V17.5001C17.5 17.9603 17.1269 18.3334 16.6667 18.3334C16.2064 18.3334 15.8333 17.9603 15.8333 17.5001V16.6667C15.8333 14.8258 14.3409 13.3334 12.5 13.3334H7.5Z"
            fill="currentColor"
          />
        </svg>
      )
    case 'group':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clipPath="url(#clip0_245_633)">
            <path
              d="M10 5.85938C8.38457 5.85938 7.07031 4.54512 7.07031 2.92969C7.07031 1.31426 8.38457 0 10 0C11.6154 0 12.9297 1.31426 12.9297 2.92969C12.9297 4.54512 11.6154 5.85938 10 5.85938ZM10 1.17188C9.03074 1.17188 8.24219 1.96043 8.24219 2.92969C8.24219 3.89895 9.03074 4.6875 10 4.6875C10.9693 4.6875 11.7578 3.89895 11.7578 2.92969C11.7578 1.96043 10.9693 1.17188 10 1.17188Z"
              fill="currentColor"
            />
            <path
              d="M0.392184 16.7382C-0.415551 15.3392 0.0655044 13.5439 1.46453 12.7362C2.86363 11.9285 4.65886 12.4095 5.46656 13.8085C6.27617 15.2108 5.79664 17.0008 4.39422 17.8105C2.98632 18.6234 1.19476 18.1283 0.392184 16.7382ZM2.05047 13.751C1.21105 14.2356 0.922458 15.3128 1.40707 16.1522C1.88937 16.9876 2.96511 17.2824 3.80828 16.7956C4.64961 16.3099 4.9375 15.2359 4.45172 14.3944C3.96707 13.5551 2.88992 13.2664 2.05047 13.751Z"
              fill="currentColor"
            />
            <path
              d="M15.6059 17.8105C14.2036 17.001 13.7238 15.2109 14.5335 13.8085C15.3412 12.4095 17.1366 11.9284 18.5356 12.7362C19.9345 13.5439 20.4156 15.3392 19.6079 16.7382C18.8038 18.1309 17.0106 18.6216 15.6059 17.8105ZM15.5484 14.3945C15.0627 15.2358 15.3504 16.3099 16.1918 16.7957C17.0312 17.2803 18.1084 16.9917 18.5931 16.1523C19.0777 15.3128 18.7891 14.2357 17.9497 13.7511C17.1058 13.2639 16.0305 13.5595 15.5484 14.3945Z"
              fill="currentColor"
            />
            <path
              d="M2.39448 10.7458L1.22437 10.6814C1.30085 9.29234 1.71265 7.92547 2.41526 6.72855C3.09843 5.56473 4.05647 4.55805 5.18585 3.81738L5.82851 4.7973C3.81046 6.12082 2.52671 8.34457 2.39448 10.7458Z"
              fill="currentColor"
            />
            <path
              d="M17.6055 10.7458C17.4733 8.34457 16.1896 6.12082 14.1715 4.7973L14.8142 3.81738C15.9435 4.55805 16.9016 5.56469 17.5848 6.72855C18.2874 7.92547 18.6992 9.29234 18.7756 10.6814L17.6055 10.7458Z"
              fill="currentColor"
            />
            <path
              d="M10 20.0001C8.60641 20.0001 7.27344 19.6833 6.03809 19.0584L6.56703 18.0127C7.63684 18.5538 8.79188 18.8282 10 18.8282C11.2081 18.8282 12.3632 18.5538 13.433 18.0127L13.9619 19.0585C12.7266 19.6833 11.3936 20.0001 10 20.0001Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_245_633">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )
    case 'permission':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M16.6451 11.4989V9.32286C16.6451 7.64062 15.2765 6.27186 13.5943 6.27186C12.421 6.27186 11.401 6.93795 10.8907 7.91134C10.4631 7.49797 9.94907 7.19124 9.38504 7.01099C10.1447 6.34124 10.6249 5.36131 10.6249 4.27131C10.6249 2.25745 8.98658 0.618896 6.97261 0.618896C4.95887 0.618896 3.32054 2.25745 3.32054 4.27131C3.32054 5.36246 3.80167 6.34307 4.56259 7.01306C2.99581 7.51896 1.85925 8.99125 1.85925 10.7242V16.357C1.85925 16.6604 2.10521 16.9063 2.40861 16.9063H9.19481V17.7129C9.19481 18.6261 9.93772 19.369 10.8509 19.369H16.3377C17.2509 19.369 17.9937 18.6261 17.9937 17.7129V13.6214C17.9937 12.6845 17.4413 11.8744 16.6451 11.4989ZM13.5943 7.37047C14.6708 7.37047 15.5465 8.24639 15.5465 9.32286V11.2758H11.6419V9.32286C11.6419 8.24639 12.5178 7.37047 13.5943 7.37047ZM6.97261 1.71761C8.38069 1.71761 9.52631 2.86311 9.52631 4.27131C9.52631 5.67951 8.38069 6.82524 6.97261 6.82524C5.56464 6.82524 4.41914 5.67951 4.41914 4.27131C4.41914 2.86311 5.56476 1.71761 6.97261 1.71761ZM2.95797 15.8078V10.7242C2.95797 9.1801 4.21401 7.92384 5.75831 7.92384H8.18714C9.15135 7.92384 10.0276 8.40508 10.546 9.21462C10.5448 9.25062 10.5433 9.28651 10.5433 9.32286V11.4989C9.74715 11.8744 9.19481 12.6845 9.19481 13.6213V15.8077H2.95797V15.8078ZM16.8951 17.713C16.8951 18.0203 16.645 18.2703 16.3377 18.2703H10.8508C10.5435 18.2703 10.2934 18.0203 10.2934 17.713V13.6214C10.2934 12.9338 10.8527 12.3744 11.5404 12.3744H15.6481C16.3357 12.3744 16.8951 12.9338 16.8951 13.6214V17.713Z"
            fill="currentColor"
          />
          <path
            d="M13.5943 14.0571C13.291 14.0571 13.0449 14.3032 13.0449 14.6065V16.0649C13.0449 16.3683 13.291 16.6142 13.5943 16.6142C13.8977 16.6142 14.1435 16.3683 14.1435 16.0649V14.6065C14.1435 14.3032 13.8977 14.0571 13.5943 14.0571Z"
            fill="currentColor"
          />
        </svg>
      )
    case 'json-preview':
      return (
        <svg width="20" height="20" viewBox="0 0 32 32">
          <g>
            <path
              fill="currentColor"
              d="m11 30h-1c-2.2056 0-4-1.7944-4-4v-5.5c0-.626-.2993-1.2241-.8003-1.5996l-2.7998-2.1006c-.252-.1885-.3999-.4849-.3999-.7998s.1479-.6113.3999-.7998l2.7998-2.1001c.501-.376.8003-.9741.8003-1.6001v-5.5029c0-2.2041 1.793-3.9971 3.9971-3.9971h1.0029c.5522 0 1 .4478 1 1s-.4478 1-1 1h-1.0029c-1.1011 0-1.9971.896-1.9971 1.9971v5.5029c0 1.2524-.5981 2.4487-1.6001 3.2002l-1.7334 1.2998 1.7334 1.3003c1.002.751 1.6001 1.9473 1.6001 3.1997v5.5c0 1.103.897 2 2 2h1c.5522 0 1 .4478 1 1s-.4478 1-1 1z"
            />
          </g>
          <g>
            <path
              fill="currentColor"
              d="m22 30h-1c-.5522 0-1-.4478-1-1s.4478-1 1-1h1c1.103 0 2-.897 2-2v-5.5c0-1.2524.5981-2.4487 1.6001-3.2002l1.7334-1.2998-1.7334-1.3003c-1.002-.751-1.6001-1.9473-1.6001-3.1997v-5.5029c0-1.1011-.896-1.9971-1.9971-1.9971h-1.0029c-.5522 0-1-.4478-1-1s.4478-1 1-1h1.0029c2.2041 0 3.9971 1.793 3.9971 3.9971v5.5029c0 .626.2993 1.2241.8003 1.5996l2.7998 2.1006c.252.1885.3999.4849.3999.7998s-.1479.6113-.3999.7998l-2.7998 2.1001c-.501.376-.8003.9741-.8003 1.6001v5.5c0 2.2056-1.7944 4-4 4z"
            />
          </g>
        </svg>
      )
  }
}
