import { FC } from 'react'

interface DeleteThinIconProps {
  size?: number
}

export const DeleteThinIcon: FC<DeleteThinIconProps> = ({ size }) => {
  return (
    <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_250_164)">
        <path
          d="M19.875 5.25H19.3158L17.9714 0.54375C17.8796 0.221719 17.5852 0 17.2505 0H6.75002C6.41533 0 6.12096 0.222188 6.02908 0.54375L4.68471 5.25H4.12549C3.71111 5.25 3.37549 5.58563 3.37549 6V9C3.37549 9.41438 3.71111 9.75 4.12549 9.75H4.57549L6.00471 23.3283C6.04502 23.7098 6.36658 24 6.75049 24H17.2505C17.6344 24 17.956 23.7103 17.9963 23.3283L19.4255 9.75H19.8755C20.2899 9.75 20.6255 9.41438 20.6255 9V6C20.6255 5.58563 20.2899 5.25 19.8755 5.25H19.875ZM9.75002 1.5H14.25V3H9.75002V1.5ZM7.3158 1.5H8.25002V3.75C8.25002 4.16437 8.58564 4.5 9.00002 4.5H15C15.4144 4.5 15.75 4.16437 15.75 3.75V1.5H16.6842L17.7558 5.25H6.24424L7.3158 1.5ZM16.575 22.5H7.42502L6.08299 9.75H17.9166L16.5746 22.5H16.575ZM19.125 8.25H4.87502V6.75H19.125V8.25Z"
          fill="currentColor"
        />
        <path
          d="M13.8333 20.2491C13.8473 20.25 13.8614 20.25 13.8755 20.25C14.2706 20.25 14.6016 19.9411 14.6236 19.5417L14.9986 12.7917C15.0216 12.3783 14.7047 12.0244 14.2912 12.0014C13.8773 11.9784 13.5239 12.2953 13.5009 12.7088L13.1259 19.4588C13.103 19.8722 13.4198 20.2261 13.8333 20.2491Z"
          fill="currentColor"
        />
        <path
          d="M9.37593 19.5417C9.39796 19.9411 9.7289 20.25 10.1241 20.25C10.1381 20.25 10.1522 20.25 10.1662 20.249C10.5797 20.2261 10.8966 19.8722 10.8736 19.4587L10.4986 12.7087C10.4756 12.2953 10.1222 11.9789 9.70827 12.0014C9.29484 12.0243 8.97796 12.3782 9.00093 12.7917L9.37593 19.5417Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_250_164">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
