import { FC } from 'react'

interface ShapesIconProps {
  size?: number
}

export const ShapesIcon: FC<ShapesIconProps> = ({ size }) => {
  return (
    <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none">
      <path
        d="M21 10.75H15C14.536 10.7494 14.0913 10.5649 13.7632 10.2368C13.4351 9.90875 13.2506 9.46396 13.25 9V3C13.2506 2.53604 13.4351 2.09125 13.7632 1.76319C14.0913 1.43512 14.536 1.25056 15 1.25H21C21.464 1.25056 21.9087 1.43512 22.2368 1.76319C22.5649 2.09125 22.7494 2.53604 22.75 3V9C22.7494 9.46396 22.5649 9.90875 22.2368 10.2368C21.9087 10.5649 21.464 10.7494 21 10.75ZM15 2.75C14.9337 2.75013 14.8702 2.77651 14.8234 2.82336C14.7765 2.87022 14.7501 2.93373 14.75 3V9C14.7501 9.06627 14.7765 9.12978 14.8234 9.17664C14.8702 9.22349 14.9337 9.24987 15 9.25H21C21.0663 9.24987 21.1298 9.22349 21.1766 9.17664C21.2235 9.12978 21.2499 9.06627 21.25 9V3C21.2499 2.93373 21.2235 2.87022 21.1766 2.82336C21.1298 2.77651 21.0663 2.75013 21 2.75H15Z"
        fill="currentColor"
      />
      <path
        d="M17.9999 23.2451C17.7701 23.2456 17.5425 23.2006 17.3301 23.1128C17.1178 23.025 16.9249 22.8961 16.7626 22.7334L13.2665 19.2373C12.9389 18.9089 12.7549 18.4639 12.7549 18C12.7549 17.5361 12.9389 17.0911 13.2665 16.7627L16.7626 13.2666C17.0909 12.9388 17.5359 12.7546 17.9999 12.7546C18.4639 12.7546 18.9089 12.9388 19.2372 13.2666L22.7333 16.7627C23.0611 17.091 23.2452 17.536 23.2452 18C23.2452 18.464 23.0611 18.909 22.7333 19.2373L19.2372 22.7334C19.0749 22.8961 18.882 23.025 18.6696 23.1128C18.4573 23.2006 18.2297 23.2456 17.9999 23.2451ZM17.9999 14.2539C17.967 14.2538 17.9345 14.2603 17.9042 14.2728C17.8738 14.2854 17.8463 14.3039 17.8231 14.3272L14.327 17.8232C14.3038 17.8465 14.2854 17.874 14.2728 17.9043C14.2603 17.9347 14.2538 17.9672 14.2538 18C14.2538 18.0328 14.2603 18.0653 14.2728 18.0957C14.2854 18.126 14.3038 18.1536 14.327 18.1768L17.8231 21.6729C17.8463 21.6961 17.8739 21.7145 17.9042 21.7271C17.9345 21.7396 17.9671 21.7461 17.9999 21.7461C18.0327 21.7461 18.0652 21.7396 18.0955 21.7271C18.1259 21.7145 18.1534 21.6961 18.1766 21.6729L21.6727 18.1768C21.696 18.1536 21.7144 18.126 21.7269 18.0957C21.7395 18.0653 21.746 18.0328 21.746 18C21.746 17.9672 21.7395 17.9347 21.7269 17.9043C21.7144 17.874 21.696 17.8465 21.6727 17.8232L18.1766 14.3272C18.1535 14.3039 18.1259 14.2854 18.0956 14.2728C18.0653 14.2603 18.0327 14.2538 17.9999 14.2539Z"
        fill="currentColor"
      />
      <path
        d="M9 10.75H3C2.53604 10.7494 2.09125 10.5649 1.76319 10.2368C1.43512 9.90875 1.25056 9.46396 1.25 9V3C1.25056 2.53604 1.43512 2.09125 1.76319 1.76319C2.09125 1.43512 2.53604 1.25056 3 1.25H9C9.46396 1.25056 9.90875 1.43512 10.2368 1.76319C10.5649 2.09125 10.7494 2.53604 10.75 3V9C10.7494 9.46396 10.5649 9.90875 10.2368 10.2368C9.90875 10.5649 9.46396 10.7494 9 10.75ZM3 2.75C2.93373 2.75013 2.87022 2.77651 2.82336 2.82336C2.77651 2.87022 2.75013 2.93373 2.75 3V9C2.75013 9.06627 2.77651 9.12978 2.82336 9.17664C2.87022 9.22349 2.93373 9.24987 3 9.25H9C9.06627 9.24987 9.12978 9.22349 9.17664 9.17664C9.22349 9.12978 9.24987 9.06627 9.25 9V3C9.24987 2.93373 9.22349 2.87022 9.17664 2.82336C9.12978 2.77651 9.06627 2.75013 9 2.75H3Z"
        fill="currentColor"
      />
      <path
        d="M9 22.75H3C2.53604 22.7494 2.09125 22.5649 1.76319 22.2368C1.43512 21.9087 1.25056 21.464 1.25 21V15C1.25056 14.536 1.43512 14.0913 1.76319 13.7632C2.09125 13.4351 2.53604 13.2506 3 13.25H9C9.46396 13.2506 9.90875 13.4351 10.2368 13.7632C10.5649 14.0913 10.7494 14.536 10.75 15V21C10.7494 21.464 10.5649 21.9087 10.2368 22.2368C9.90875 22.5649 9.46396 22.7494 9 22.75ZM3 14.75C2.93373 14.7501 2.87022 14.7765 2.82336 14.8234C2.77651 14.8702 2.75013 14.9337 2.75 15V21C2.75013 21.0663 2.77651 21.1298 2.82336 21.1766C2.87022 21.2235 2.93373 21.2499 3 21.25H9C9.06627 21.2499 9.12978 21.2235 9.17664 21.1766C9.22349 21.1298 9.24987 21.0663 9.25 21V15C9.24987 14.9337 9.22349 14.8702 9.17664 14.8234C9.12978 14.7765 9.06627 14.7501 9 14.75H3Z"
        fill="currentColor"
      />
    </svg>
  )
}
