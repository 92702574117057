import { FC } from 'react'

export const SaveIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_250_77)">
        <path
          d="M22.9726 3.65011L20.4035 1.0342C20.402 1.0327 20.4006 1.0312 20.399 1.0297C19.7351 0.365719 18.8522 0 17.9131 0C17.4865 0 2.53486 0 2.10938 0C0.946266 0 0 0.946266 0 2.10938V21.8906C0 23.0537 0.946266 24 2.10938 24C3.1485 24 21.0742 24 21.8906 24C23.0537 24 24 23.0537 24 21.8906V6.18061C24 5.24053 23.616 4.29488 22.9726 3.65011ZM15.5625 1.40625H16.9688V7.125H15.5625V1.40625ZM12.75 1.40625H14.1562V7.125H12.75V1.40625ZM7.03125 1.40625H11.3438V7.125H7.03125V1.40625ZM18.375 22.5938H5.625V15.5625H18.375V22.5938ZM18.375 14.1562H5.625V12.75H18.375V14.1562ZM22.5938 21.8906H22.5938C22.5938 22.2783 22.2783 22.5938 21.8906 22.5938H19.7812V12.0469C19.7812 11.6586 19.4664 11.3438 19.0781 11.3438H4.92188C4.53356 11.3438 4.21875 11.6586 4.21875 12.0469V22.5938H2.10938C1.72167 22.5938 1.40625 22.2783 1.40625 21.8906V2.10938C1.40625 1.72167 1.72167 1.40625 2.10938 1.40625H5.625V7.82812C5.625 8.21644 5.93981 8.53125 6.32812 8.53125C6.78516 8.53125 17.1569 8.53125 17.6719 8.53125C18.0602 8.53125 18.375 8.21644 18.375 7.82812V1.4573C18.7611 1.54313 19.1159 1.73602 19.4027 2.02205L21.9715 4.63767C21.973 4.63917 21.9744 4.64067 21.976 4.64217C22.3628 5.02903 22.5938 5.60414 22.5938 6.18061V21.8906Z"
          fill="currentColor"
        />
        <path
          d="M16.2656 16.9688H7.73438C7.34606 16.9688 7.03125 17.2836 7.03125 17.6719C7.03125 18.0602 7.34606 18.375 7.73438 18.375H16.2656C16.6539 18.375 16.9688 18.0602 16.9688 17.6719C16.9688 17.2836 16.6539 16.9688 16.2656 16.9688Z"
          fill="currentColor"
        />
        <path
          d="M16.2656 19.7812H7.73438C7.34606 19.7812 7.03125 20.0961 7.03125 20.4844C7.03125 20.8727 7.34606 21.1875 7.73438 21.1875H16.2656C16.6539 21.1875 16.9688 20.8727 16.9688 20.4844C16.9688 20.0961 16.6539 19.7812 16.2656 19.7812Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_250_77">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
